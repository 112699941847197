import { Link, Outlet } from "@remix-run/react"
import logo from "~/assets/logo.webp"

export default function Auth() {
  return (
    <>
      <div className="fixed top-0 insert-x-0 z-20">
        <div className="flex h-16 items-center mx-16">
          <Link to="/" className="block text-left flex flex-col flex-none justify-center item-start bg-primary h-16">
            <img className="block px-4 w-40" style={{ height: "auto" }} src={logo} alt="Make Love Not Porn" />
            <span className="block pl-4 -mt-2 font-tight text-white text-[0.70rem]">MakeLoveNotPorn</span>
          </Link>
        </div>
      </div>
      <Outlet />
    </>
  )
}
